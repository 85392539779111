.card {
  width: 400px;
}

.upgradeModal {
  width: 890px;
  background: #0d1221;
  box-shadow: none;
  border: 1px solid #00AEC757;
  padding: 0px !important;
  overflow: hidden;
}

@media (max-width: 639px) {
  .card {
    width: 350px;
  }

  .upgradeModal {
    width: calc(100% - 30px);
    margin: 0 auto;
  }
}

.modal-backdrop {
  backdrop-filter: blur(5px);
}