.overAll {
  .mobileBox {
    display: none;

    .appBar {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background-color: var(--dark);
      background-image: none;
    }
  }

  .desktopBox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    .listBox {
      background-color: var(--dark);
      overflow-y: scroll;
    }

    .topNavContainer {
      display: flex;
      flex-direction: column;
      width: calc(100% - 275px);

      .topNav {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: var(--dark);
      }

      .childrenBox {
        border: 1px solid rgba(255, 255, 255, 0.12);
        flex-grow: 1;
        overflow-y: scroll;
      }
    }
  }
}

@media (max-width: 639px) {
  .overAll {
    .mobileBox {
      display: contents;
    }

    .desktopBox {
      display: none !important;
    }
  }
}


@media (min-width: 1600px) {
  .overAll {
    .desktopBox {
      .topNavContainer {
        .childrenBox {
          position: relative;
          width: 1320px !important;
        }
      }
    }
  }
}