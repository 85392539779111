.overAll {
  border-radius: 16px;
  object-fit: cover;
  border-radius: 16px;
  margin-top: 20px;
}

.card_1 {
  border: 0.5px solid rgba(107, 106, 115, 0.4);
  background: url(../../images/card_1.webp) lightgray -27.351px -53.582px /
      132.137% 105.33% no-repeat,
    linear-gradient(
      270deg,
      rgba(255, 255, 255, 0.01) -20%,
      rgba(40, 40, 40, 0.09) 75.76%,
      rgba(255, 255, 255, 0.01) 123.64%
    );
  box-shadow: -22px -25px 150px 0px rgba(253, 167, 28, 0.05) inset,
    22px 18px 150px 15px rgba(234, 0, 33, 0.05) inset;
  backdrop-filter: blur(50px);
}
.card_2 {
  border: 0.5px solid #e1e0e0;
  background: url(../../images/card_1.webp) lightgray -2.221px -123.434px /
      100.779% 125.629% no-repeat,
    linear-gradient(
      270deg,
      rgba(255, 255, 255, 0.01) -20%,
      rgba(40, 40, 40, 0.09) 75.76%,
      rgba(255, 255, 255, 0.01) 123.64%
    );
  box-shadow: -22px -25px 150px 0px rgba(253, 167, 28, 0.05) inset,
    22px 18px 150px 15px rgba(234, 0, 33, 0.05) inset;
  backdrop-filter: blur(50px);
}

.card_3 {
  border-radius: 16px;
  border: 0.5px solid var(--Border, #6b6a73);
  background: url(../../images/card_1.webp) lightgray -23.682px -17.017px /
      132.137% 105.33% no-repeat,
    linear-gradient(
      270deg,
      rgba(255, 255, 255, 0.01) -20%,
      rgba(40, 40, 40, 0.09) 75.76%,
      rgba(255, 255, 255, 0.01) 123.64%
    );
  box-shadow: -22px -25px 150px 0px rgba(253, 167, 28, 0.05) inset,
    22px 18px 150px 15px rgba(234, 0, 33, 0.05) inset;
  backdrop-filter: blur(50px);
}

.card_4 {
  border: 0.5px solid rgba(107, 106, 115, 0.4);
  background: url(../../images/card_1.webp) lightgray -27.351px -34.033px /
      132.137% 105.33% no-repeat,
    linear-gradient(
      270deg,
      rgba(255, 255, 255, 0.01) -20%,
      rgba(40, 40, 40, 0.09) 75.76%,
      rgba(255, 255, 255, 0.01) 123.64%
    );
  box-shadow: -22px -25px 150px 0px rgba(253, 167, 28, 0.05) inset,
    22px 18px 150px 15px rgba(234, 0, 33, 0.05) inset;
  backdrop-filter: blur(50px);
}

.card_5 {
  border-radius: 16px;
  border: 0.5px solid var(--Gradient, #35d7f3);
  background: url(../../images/card_1.webp) lightgray -27.351px -10.688px /
      132.137% 105.33% no-repeat,
    linear-gradient(
      270deg,
      rgba(255, 255, 255, 0.01) -20%,
      rgba(40, 40, 40, 0.09) 75.76%,
      rgba(255, 255, 255, 0.01) 123.64%
    );
  box-shadow: -22px -25px 150px 0px rgba(253, 167, 28, 0.05) inset,
    22px 18px 150px 15px rgba(234, 0, 33, 0.05) inset;
  backdrop-filter: blur(50px);
}

.card_6 {
  border-radius: 16px;
  border: 0.5px solid rgba(225, 224, 224, 0.67);
  background: url(../../images/card_1.webp) lightgray -27.351px -30.94px /
      132.137% 105.33% no-repeat,
    linear-gradient(
      270deg,
      rgba(255, 255, 255, 0.01) -20%,
      rgba(40, 40, 40, 0.09) 75.76%,
      rgba(255, 255, 255, 0.01) 123.64%
    );
  box-shadow: -22px -25px 150px 0px rgba(253, 167, 28, 0.05) inset,
    22px 18px 150px 15px rgba(80, 189, 178, 0.05) inset;
  backdrop-filter: blur(50px);
}

.transparent {
}
